<template>
  <div>
    <ul class="pay-result" v-show="!loading">
      <li class="pay-status" v-if="result.status == 1">
        <van-icon name="passed" /> 支付成功
      </li>
      <li class="pay-status" v-if="result.status != 1">
        <van-icon name="close" />支付失败
      </li>
      <li v-if="result.status == 1">支付名称：{{ result.order_name }}</li>
      <li v-if="result.status == 1">支付金额：{{ result.amount }}元</li>
      <li>
        <van-button class="btn-result" v-if="result.status == 0" type="primary" @click="goPay">重新支付</van-button>
        <van-button class="btn-result" v-else type="primary" @click="goHome">返回首页</van-button>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  data() {
    return {
      result: {},
      loading: true
    }
  },
  watch: {
    '$route.query': {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.getQueryOrder(newValue.out_trade_no)
      }
    }
  },
  methods: {
    getQueryOrder(out_trade_no) {
      this.$api.GET_QUERY_ORDER({ out_trade_no: out_trade_no }).then(res => {
        this.result = res
      }) .finally(() => {
        this.loading = false;
      });
    },
    goPay() {
      this.$router.push({
        name: "Pay",
        query: {
          job_id: this.result.job_id
        }
      });
    },
    goHome(){
      this.$router.push({
        name: "Index"
      });
    }
  },
}
</script>
<style >
.pay-result {
  margin: 50px auto;
}

.pay-result li {
  margin: 15px 0;
}

.pay-result .pay-status {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #e95944;
}

.pay-result .pay-status i {
  font-size: 26px;
  margin-right: 10px;
  color: #e95944;
}

.pay-result .btn-result {
  border: none;
  border-radius: 20px !important;
  background: linear-gradient(90deg, #e95944 0%, #e95944 100%);
}

@media (min-width: 992px) {

  .pay-result {
    margin: 100px auto;
  }

  .pay-result li {
    margin: 15px 0;
  }

  .pay-result .pay-status i {
    font-size: 26px;
    margin-right: 10px;
  }

}
</style>